import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import GroupAccordion from "../accordions/GroupAccordion";
import { IGroup } from "../interfaces/IGroup";
import AttributeSelector from "../attributeSelector/AttributeSelector";
import { IConfiguratorFromTemplateResponse } from "../interfaces/IConfiguratorFromTemplateResponse";
import { IAttribute } from "../interfaces/IAttribute";
import { INavigationMapping } from "../interfaces/INavigationMapping";
import ROUTES from "../../../../constants/Routes";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfiguratorData } from "../../contexts/ConfiguratorDataContext";

interface ConfigurationSummaryProps {
  configFromTemplateDataFromParent: IConfiguratorFromTemplateResponse;
  updateConfigurator: (attributes: IAttribute[] | undefined, attribute: IAttribute) => void;
  updateConfiguratorWithUserEnteredValue: (attribute: IAttribute, value: string) => void;
  handleConfiguratorDrawerClose: () => void;
  isValidConfiguration: boolean;
  quantityAdd: Function;
  quantitySubtract: Function;
}

const ConfiguratorSummary = ({
  configFromTemplateDataFromParent,
  updateConfigurator,
  handleConfiguratorDrawerClose,
  isValidConfiguration,
  updateConfiguratorWithUserEnteredValue,
  quantityAdd,
  quantitySubtract,
}: ConfigurationSummaryProps) => {
  const navigate = useNavigate();
  const [configData, setConfigData] = useState<IConfiguratorFromTemplateResponse>();
  const [groupAttributeIndex, setGroupAttributeIndex] = useState<number>(0); // current attribute selected index in its group array
  const [currentGroupIndex, setCurrentGroupIndex] = useState<number>(0); // current group index in group array
  const [showingGroupArray, setShowingGroupArray] = useState<INavigationMapping[]>([]); // array of groups that are in accordion
  const [isBackButtonDisabled, setIsBackButtonDisabled] = useState<boolean>(false);
  const [isContinueButtonDisabled, setIsContinueButtonDisabled] = useState<boolean>(false);
  const [modifiedPresentationGroups, setModifiedPresentationGroups] = useState<IGroup[]>([]);
  const [currentAttribute, setCurrentAttribute] = useState<IAttribute | null>(null);
  const { searchQuery, setSearchQuery, isViewSummaryVisible } = useConfiguratorData();
  const location = useLocation();
  const tids = new URLSearchParams(location.search).get("tids");

  useEffect(() => {
    setSearchQuery("");
  }, [currentAttribute]);

  const handleConfigurationAccept = async () => {
    if (configData) {
      let route = `${ROUTES.PRODUCT}?catalogNumber=${configData?.pid}&tids=${tids}&type=Unknown`;
      let cid = `&cid=${configData.cid}`;
      let completeRoute = route + cid;

      navigate(completeRoute);
    }
  };

  const handleConfigurationContinue = () => {
    const group = modifiedPresentationGroups[currentGroupIndex] || null;
    const attributes = group ? getAttributesForGroup(group) : [];
    if (group && groupAttributeIndex === attributes.length - 1) {
      const nextGroup = modifiedPresentationGroups[currentGroupIndex + 1];
      if (nextGroup) {
        setCurrentGroupIndex(currentGroupIndex + 1);
        setGroupAttributeIndex(0);
        setCurrentAttribute(getAttributesForGroup(nextGroup)[0]);
      }
    } else {
      if (attributes) {
        const currentIndex = attributes.findIndex(i => i.id === currentAttribute?.id);
        setCurrentAttribute(currentIndex !== -1 ? attributes[currentIndex + 1] : attributes[1]);
      }
      setGroupAttributeIndex(groupAttributeIndex + 1);
    }
  };

  const handleConfigurationBack = () => {
    const group = modifiedPresentationGroups[currentGroupIndex] || null;
    const attributes = group ? getAttributesForGroup(group) : [];
    if (group && groupAttributeIndex === 0) {
      const prevGroup = modifiedPresentationGroups[currentGroupIndex - 1];
      if (prevGroup) {
        setCurrentGroupIndex(currentGroupIndex - 1);
        setGroupAttributeIndex(getAttributesForGroup(prevGroup).length - 1);
        setCurrentAttribute(getAttributesForGroup(prevGroup)[getAttributesForGroup(prevGroup).length - 1]);
      }
    } else {
      if (attributes) {
        const currentIndex = attributes.findIndex(i => i.id === currentAttribute?.id);
        setCurrentAttribute(currentIndex !== -1 ? attributes[currentIndex - 1] : attributes[0]);
      }
      setGroupAttributeIndex(groupAttributeIndex - 1);
    }
  };

  const getGroupAttributeData = (attributes: string[], displayText: string) => {
    let hasAtLeastOneSelectable = false;
    attributes.forEach(attribute => {
      if (configFromTemplateDataFromParent) {
        for (const key in configFromTemplateDataFromParent) {
          try {
            if (configFromTemplateDataFromParent[key] !== undefined) {
              if (attribute === configFromTemplateDataFromParent[key].id && configFromTemplateDataFromParent[key].hasSelectables === true) {
                hasAtLeastOneSelectable = true;
                return hasAtLeastOneSelectable;
              }
            }
          } catch (e) {
            console.log("error", e);
            console.log("error", configFromTemplateDataFromParent[key]);
          }
        }
      }
    });
    return hasAtLeastOneSelectable;
  };

  const handleSetSelectedAttribute = (attribute: IAttribute, groupAttributeIndex: number, groupIndex: number) => {
    if (currentGroupIndex === 0 && groupAttributeIndex === 0) {
      setIsBackButtonDisabled(true);
    } else {
      setIsBackButtonDisabled(false);
    }
    setCurrentAttribute(attribute);
    setCurrentGroupIndex(groupIndex);
    setGroupAttributeIndex(groupAttributeIndex);
  };

  const groupArray = (index: number, groupAttributes: IAttribute[]) => {
    const isUnique = !showingGroupArray.some(group => group.index === index);
    if (isUnique && groupAttributes.length > 0) {
      const keyValueArray = {
        index: index,
        groupAttributes: groupAttributes,
      };
      setShowingGroupArray(prevShowingGroupArray => [...prevShowingGroupArray, keyValueArray]);
    }
  };

  const isExcludedGroup = (group: IGroup) => {
    let isExcluded = false;
    if (group.displayText.toLowerCase().includes("accessor") || group.displayText.toLowerCase().includes("accesor")) {
      isExcluded = true;
      return isExcluded;
    }
    return isExcluded;
  };

  useEffect(() => {
    setConfigData(configFromTemplateDataFromParent);
  }, [configFromTemplateDataFromParent]);

  useEffect(() => {
    let modGroups: IGroup[] = [];
    configData?.presentation?.groups.map((group: IGroup, index: any) => {
      if (
        group.attributes.length > 0 &&
        getGroupAttributeData(group.attributes, group.displayText) &&
        configData &&
        isExcludedGroup(group) === false
      ) {
        modGroups.push(group);
      }
      if (modGroups) {
        setModifiedPresentationGroups(modGroups);
      }
    });
  }, [configData]);

  useEffect(() => {
    setCurrentGroupIndex(0);
    setGroupAttributeIndex(0);
  }, [configData?.cid]);

  useEffect(() => {
    if (showingGroupArray[0]?.index) {
      setCurrentGroupIndex(showingGroupArray[0].index);
    }
  }, [showingGroupArray]);

  useEffect(() => {
    const attr = modifiedPresentationGroups[currentGroupIndex] ? getAttributesForGroup(modifiedPresentationGroups[currentGroupIndex]) : null;
    if (attr && currentAttribute && modifiedPresentationGroups) {
      if (attr && attr.length - 1 <= attr.indexOf(currentAttribute) && currentGroupIndex >= modifiedPresentationGroups.length - 1) {
        setIsContinueButtonDisabled(true);
      } else setIsContinueButtonDisabled(false);
    } else setIsBackButtonDisabled(false);
  }, [currentGroupIndex, groupAttributeIndex]);

  const isLastIndex = () => {
    if (
      currentAttribute &&
      getAttributesForGroup(modifiedPresentationGroups[modifiedPresentationGroups.length - 1]).indexOf(currentAttribute) >=
        getAttributesForGroup(modifiedPresentationGroups[modifiedPresentationGroups.length - 1]).length - 1
    )
      return true;
    return false;
  };

  const getAttributesForGroup = (group: IGroup) => {
    const attributesForGroup: IAttribute[] = [];
    group.attributes.forEach((attribute: string) => {
      if (configData?.attributes) {
        for (let i = 0; i < configData.attributes.length; i++) {
          if (
            attribute === configData.attributes[i].id &&
            configData.attributes[i].hasSelectables &&
            !configData.attributes[i].displayText.toLowerCase().includes("accessor") &&
            !configData.attributes[i].displayText.toLowerCase().includes("accesor")
          ) {
            attributesForGroup.push(configData.attributes[i]);
          }
          //FOR TESTING ONLY
          else if (attribute === configData.attributes[i].id && configData.attributes[i].hasSelectables === false) {
            // console.log(`getGroupAttributesData no selectable ${index}`, configData.attributes[i]);
          }
        }
      }
    });
    return attributesForGroup;
  };

  return (
    <>
      <Box sx={{ width: "100%", height: "75%", display: "flex" }}>
        <Box
          sx={{
            width: "40%",
            height: "100%",
            marginRight: "5px",
            overflow: "auto",
          }}>
          {modifiedPresentationGroups?.map((group: IGroup, index: any) => {
            if (configData) {
              return (
                <GroupAccordion
                  key={index}
                  group={group}
                  attributes={getAttributesForGroup(group)}
                  groupAttributeIndex={groupAttributeIndex}
                  currentGroupIndex={currentGroupIndex}
                  index={index}
                  groupArray={groupArray}
                  configFromTemplateDataFromParent={configData}
                  handleSetSelectedAttribute={handleSetSelectedAttribute}
                  currentAttribute={currentAttribute}
                  setCurrentAttribute={setCurrentAttribute}
                />
              );
            }
          })}
        </Box>
        {currentAttribute && !isViewSummaryVisible && (
          <Box sx={{ width: "60%", height: "100%" }}>
            <AttributeSelector
              selectedAttribute={currentAttribute}
              configFromTemplateDataFromParent={configFromTemplateDataFromParent}
              groupAttributeIndex={groupAttributeIndex}
              updateConfigurator={updateConfigurator}
              updateConfiguratorWithUserEnteredValue={updateConfiguratorWithUserEnteredValue}
              setIsContinueButtonDisabled={setIsContinueButtonDisabled}
              quantityAdd={quantityAdd}
              quantitySubtract={quantitySubtract}
            />
          </Box>
        )}
        {isViewSummaryVisible && (
          <Box sx={{ width: "60%", height: "100%" }}>
            <h1>Product Configuration Summary</h1>
            <Box sx={{ overflowY: "auto", height: "90%" }}>
              {modifiedPresentationGroups.map(group => {
                const attributes = getAttributesForGroup(group);
                return (
                  <Box key={group.displayText}>
                    <h2 style={{ margin: 0 }}>{group.displayText}</h2>
                    {attributes.map(attr => {
                      const value = configData?.attributes?.find(x => x.id === attr.value);
                      if (!attr || !value) return null;
                      return (
                        <Box sx={{ pl: 4, py: 0 }}>
                          <h3 style={{ padding: 0, marginTop: "15px", marginBottom: "3px" }}>{attr?.displayText}</h3>
                          <Box sx={{ p: 0 }}>{value.displayText}</Box>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
      {!isViewSummaryVisible && (
        <Box sx={{ display: "flex", justifyContent: "flex-end", height: "5vw", alignItems: "end" }}>
          <Box>
            <Button sx={{ mr: 2 }} variant="outlined" onClick={() => handleConfiguratorDrawerClose()}>
              Cancel
            </Button>
            <Button sx={{ mr: 2 }} variant="outlined" disabled={isBackButtonDisabled} onClick={() => handleConfigurationBack()}>
              Back
            </Button>
            <Button sx={{ mr: 2 }} variant="outlined" disabled={isLastIndex()} onClick={() => handleConfigurationContinue()}>
              Continue
            </Button>
            <Button disabled={!isValidConfiguration} variant="contained" onClick={() => handleConfigurationAccept()}>
              Accept
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ConfiguratorSummary;
